import * as types from '../mutation-types'

export default {
    namespaced: true,
    state:{
        materials:[],
        // myMaterials: [
        //     {
        //         materials: [],
        //         companies: []
        //     }
        // ]
        myMaterials: []
    },

    actions:{
        fetchAll({ commit }){
            return window.axios.get('/materials').then((response) => {
                commit(types.STORE_MATERIALS, response.data)
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/materials/${id}`).then((response) => {
                return response.data;
            })
        }, 
        
        fetchByFilter({ commit }, { name, materialFormId, companyId, fromPrice, toPrice, orderByPrice, orderByMinimumPrice, NameOrDescription }){
            return window.axios.get('/materials', {
                params:{
                    name,
                    materialFormId,
                    companyId,
                    fromPrice, 
                    toPrice, 
                    orderByPrice, 
                    orderByMinimumPrice,
                    NameOrDescription
                }
            })
            .then((response) => {
                return response.data
            })
        },

        create({ commit }, { data }){
            return window.axios.post('/materials', data);
        },

        update({ commit }, { id, data }){
            return window.axios.put(`materials/${id}`, data);
        },

        delete({ commit }, { id }) {
            return window.axios.delete(`materials/${id}`);
        },

        fetchMyMaterial ({ commit }, { employeeId }) {
            return window.axios.get('materials/mine', { params: { employeeId } }).then(({ data }) => {
                commit(types.STORE_MINE_MATERIALS, data)
                return data
            })
        }
    },

    mutations:{
        [types.STORE_MATERIALS](state, materials){
            state.materials = materials;
        },

        [types.STORE_MINE_MATERIALS] (state, myMaterials) {
            state.myMaterials = myMaterials[0].materials
        }
    },

    getters:{
        getMaterialById: state => id => state.materials.find(c => c.id === id),
        getMaterialByName: state => name => state.materials.find(c => c.name === name)
    }
}